<template>
    <!-- 
        Сторінка логи співробітників
       - дозволяє переглянути список дій в інсайді
        - відкрити картку дії
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @open - подія, яка спрацьовує при відкритті картки дії
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true" 
        @open="open"
        @getdata="getdata"
        @changeFilter="changeFilter" 
        @changelimit="changelimit"
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import { Logs } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store.js' // глобальне сховище даних
import { nameWorkerAcc } from '@/usabilityScripts/getNameWorker'
import { mutateActionLog, mutateFromIP } from '@/usabilityScripts/globalMutate'

let apiServe = new Logs();

export default{
    components: {
        PageHeader,
        tablecustom
    },
    data(){
        return{
            title: this.$t('Logs'), // Заголовок
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "logs"
            },
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            objParams:{
                pr1: '',
                pr2: '',
                userFilter: ''
            },
            filter: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                date1: this.currentDate(),
                date2: this.currentDate(),
                userFilter: []
            },
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: this.$t('AccountID'),
                    text: "account_id",
                    align: "left",
                    width: 200,
                    status: false
                },
                {
                    name: this.$t('Employee'),
                    text: "account_id",
                    align: "left",
                    width: 200,
                    status: true,
                    mutate: (item) => item ? this.mutateName(item) : '-'
                },
                {
                    name: this.$t('action'),
                    text: "action",
                    align: "left",
                    width: 200,
                    status: true,
                    mutate: (item) => item ? mutateActionLog(item) : '-'
                },
                {
                    name: this.$t('date_time'),
                    text: "datetime",
                    align: "left",
                    width: 200,
                    status: true
                },
                {
                    name: 'IP',
                    text: "ip",
                    align: "left",
                    width: 200,
                    status: true,
                    mutate: (item) => item ? item + " " + this.mutateFromIP(item).name : '-',
                    mutateClass: (item) => item ? this.mutateFromIP(item).color : '-'
                },
            ],
            rows: []
        }
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(page){
            /**
                Отримує дані про логи з API та встановлює їх у властивості компонента.

                @method getdata
                @param {Number} page - Номер сторінки для отримання даних.
            */

            this.filter.page = page != undefined ? page : '1';
            apiServe.getLogs(this.filter).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` logs`);
                }
            })
        },
        open(e){

            switch (e.action) {
                
                // Лог надсилання повідомлення
                case "sendSms":
                    this.eventBus.emit('sendSmsLog', {
                        status: true,
                        data: e,
                    })
                    break;
            
                default:
                    this.$toast.warning(this.$t('errSelectLog'))
                    break;
            }
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.filter['date1'] = value[0];
                    this.filter['date2'] = value[1];
                } else {
                    this.filter[name] = value[0] == "" ? '' : JSON.stringify(value); 
                }
                this.getdata();
            }
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2);
            return dateStr
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */    

            this.filter.pagelimit = limit;
            this.getdata();
        },
        mutateName(e){
            // Беремо ім'я співробітника за його ідентифікатором
            return nameWorkerAcc(e)
        },
        mutateActionLog(e){
            /*
                Функція для застосування перекладу до типу дії
            */
            return mutateActionLog(e)
        },
        mutateFromIP(e){
            /*
                Функція для застосування стилів та назви до ip юзера
            */
            return mutateFromIP(e)
        }
    }
}
</script>